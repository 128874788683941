<template>
  <div
    class="vinculacioninterna full-width row wrap justify-center items-center content-center q-pa-none q-ma-none q-px-sm"
    style="min-height: calc(100vh - 50px)"
  >
    <q-card
      class="full-width row wrap justify-center items-center content-start q-pa-none q-ma-none"
      style="min-height: calc(100vh - 60px)"
    >
      <div
        class="col-12 justify-center items-center content-center q-ma-none q-pa-none"
        style=""
      >
        <interstellar-text
          id="vinculacioninterna"
          class="text-h3 q-ma-none q-pa-none"
        >
          Vinculación Interna
        </interstellar-text>
      </div>
    </q-card>
  </div>
</template>

<style></style>

<script>
import {
  defineAsyncComponent,
  // ref,
  // onMounted,
} from "vue";

export default {
  name: "vinculacioninterna",
  components: {
    InterstellarText: defineAsyncComponent(() =>
      import("@/components/common/InterstellarText.vue")
    ),
  },

  setup() {
    console.log("VinculacioInterna ready");
    // onMounted(() => {});
    return {};
  },
};
</script>

<style lang="scss"></style>
